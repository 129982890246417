import { useTranslation } from 'react-i18next';

import { loginWithVipps } from '../../../data/api';
import style from './VippsButton.module.css';
import { ReactComponent as VippsLogo } from './VippsLogo.svg';

/**
 * Login/Register with Vipps
 *
 * Navigates the user to Vipps for login
 * on successful login the user is navigated back to our Vipps callback page
 */
interface Props {
  variant: 'register' | 'login';
  authUrlKey: string;
  className?: string;
}
const VippsButton = ({ variant, authUrlKey, className = '' }: Props) => {
  const { t: translate } = useTranslation();

  const redirectToVipps = () => {
    loginWithVipps({ authUrlKey }).then((response) => {
      if (response.status === 200) {
        window.location.assign(response.data);
      }
    });
  };

  let label = '';
  if (variant === 'login') {
    label = translate('login.loginWithVipps');
  } else {
    label = translate('register.registerWithVipps');
  }

  // Both the login and register text ends with "Vipps"
  // Since the visual last part is an svg of the word Vipps we remove it from the translated string
  let labelWithoutVipps = label.replace(' Vipps', '');

  // We hide the text and logo from screen readers with aria-hidden, and add a custom aria-label with the complete label
  // so it's read correctly
  return (
    <button
      aria-label={label}
      type="button"
      className={`hw-button ${style.button} ${className}`}
      onClick={redirectToVipps}>
      <span aria-hidden>{labelWithoutVipps}&nbsp;</span>
      <VippsLogo aria-hidden className={style.vippsLogo} />
    </button>
  );
};
export default VippsButton;
