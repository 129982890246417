import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import config from '../../../config';
import findContext from '../../../utils/FindContext';
import AccessibleH1Heading from '../../components/AccessibleH1Heading';
import { POST_REGISTER_USER } from './RegisterUser';
import Sikkerhet from './Sikkerhet.svg';

/**
 * Step 4 - Post Register User
 */
type Props = POST_REGISTER_USER & { authUrlKey: string };
const PostRegisterUser = ({ authUrlKey }: Props) => {
  const { t: translate } = useTranslation();

  // For the app to be successfully signed in, the browser needs to go to the authUrl callback
  useEffect(() => {
    if (authUrlKey.length > 0) {
      window.location.assign(`${config.contextPath}/api/oauth/authorizations/authorize/${authUrlKey}`);
    }
  }, []);

  let imagePath;
  if (process.env.NODE_ENV === 'development') {
    imagePath = Sikkerhet;
  } else {
    imagePath = findContext() + '/img/posten/Sikkerhet.svg';
  }

  return (
    <>
      <div className="hw-grid--center">
        <img style={{ maxHeight: 350 }} src={imagePath} alt="" />
      </div>
      <AccessibleH1Heading>{translate('registerUser.newUserCreated')}</AccessibleH1Heading>
      <div className="hw-block--mt-small-4" />
      <div className="hw-block--mt-medium-3" />
      <a
        className="hw-button hw-button--primary hw-button--large hw-button--full"
        href={`${config.contextPath}/profile`}>
        {translate('registerUser.goToMyPage')}
      </a>
    </>
  );
};

export default PostRegisterUser;
